import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Navigation from "../components/navigation"
import 'prismjs/themes/prism-okaidia.css';
import styles from './layout.module.scss'
import { slide as Menu } from 'react-burger-menu'


export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  var menuStyles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      right: '36px',
      top: '80px'
    },
    bmBurgerBars: {
      background: '#373a47'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: '#fff',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
      overflow: 'hidden'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em'
    },
    bmItem: {
      display: 'block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }
  return (
    <div>
      <nav className="hide-desktop">
        <Menu 
          styles={menuStyles}
          right
        >
          <Link to="/">Home</Link>
          <Link to="/services">Our Services</Link>
          <Link to="/therapists">Our Therapists</Link>
          <Link to="/clinics">Our Clinics</Link>
          <Link to="/contact">Contact</Link>
        </Menu>
      </nav>
      <div className="site-wrapper">
        <header className="site-header">
          <div className={`${styles.siteTitle} site-title`}>
            <img className={styles.brand} src="/assets/logo_cropped.jpg" alt="Sydney Hand Therapy logo" />
            <Link to="/">{data.site.siteMetadata.title}</Link>
          </div>
          <Navigation />
        </header>
        {children}
        <footer className="site-footer">
          <p>&copy; {new Date().getFullYear()} Sydney Hand Therapy &ndash; <Link to="/contact">Contact us</Link> &ndash; <Link to="/clinics">Find a Clinic</Link></p>
          <span id="credits" style={{display: 'none'}} dangerouslySetInnerHTML={{
            __html: `<!-- This website was build using the awesome template Delog Crafted with ❤️ by W3Layouts (https://w3layouts.com) -->`
          }} />
        </footer>
      </div>
    </div>
  )
}
